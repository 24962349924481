<template>
  <v-row>
    <v-col cols="12">
      <strong>Composição</strong>
      <v-divider/>
    </v-col>
      <v-col cols="12" md="12">
        <search-box
            :label="labelItem"
            :emit-all-properties="true"
            popup-label="Selecione um Item"
            :id.sync="value.idItem"
            :descricaoFormatada.sync="value.descricaoItem"
            :descTipoItem.sync="value.descTipoItem"
            :hidden-clear="false"
            :value="item"
            :loader-fn="loaderItem"
            item-key="id"
            item-text="descricaoFormatada"
            :rules="[
                this.$validators.notNullOrUndefined
            ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhum Item encontrado
            </p>
          </template>
        </search-box>
      </v-col>    
      <v-col cols="12" sm="2">
        <v-text-field dense
            label="Ano do PAC"
            v-model="value.anoPac" 
            type="number"/>
      </v-col>
      <v-col cols="12" md="5">
        <search-box
            label="* Orgão"
            popup-label="Selecione um Orgão"
            :id.sync="value.idOrgao"
            :descricaoFormatada.sync="value.descricaoOrgao"
            :hidden-clear="false"
            :value="orgao"
            :loader-fn="loaderOrgao"
            item-key="id"
            item-text="descricaoFormatada"
            :rules="[
                this.$validators.notNullOrUndefined
            ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhum Orgão encontrado
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="5">
        <search-box ref="comboUnidade"
            label="* Unidade"
            popup-label="Selecione uma Unidade"
            :id.sync="value.idUnidade"
            :descricaoFormatada.sync="value.descricaoUnidade"
            :hidden-clear="false"
            :value="unidade"
            :loader-fn="loaderUnidade"
            item-key="id"
            item-text="descricaoFormatada"
            :rules="[
                this.$validators.notNullOrUndefined
            ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhuma Unidade encontrada
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="4">
        <search-box
            label="* Região"
            popup-label="Selecione uma Região"
            :id.sync="value.idRegiao"
            :descricaoFormatada.sync="value.descricaoRegiao"
            :hidden-clear="false"
            :value="regiao"
            :loader-fn="loaderRegiao"
            item-key="id"
            item-text="descricaoFormatada"
            :rules="[
                this.$validators.notNullOrUndefined
            ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhuma Região encontrada
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="4">
        <search-box
            label="* Almoxarifado"
            popup-label="Selecione um Almoxarifado"
            :id.sync="value.idAlmoxarifado"
            :descricaoFormatada.sync="value.descricaoAlmoxarifado"
            :hidden-clear="false"
            :value="almoxarifado"
            :loader-fn="loaderAlmoxarifado"
            item-key="id"
            item-text="descricaoFormatada"
            :rules="[
                this.$validators.notNullOrUndefined
            ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhum Almoxarifado encontrado
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="4">
        <search-box
            label="* Unidade de Medida"
            popup-label="Selecione uma Unidade de Medida"
            :id.sync="value.idUnidadeMedida"
            :descricaoFormatada.sync="value.descricaoUnidadeMedida"
            :hidden-clear="false"
            :value="unidadeMedida"
            :loader-fn="loaderUnidadeMedida"
            item-key="id"
            item-text="descricaoFormatada"
            :rules="[
                this.$validators.notNullOrUndefined
            ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhuma Unidade de Medida encontrada
            </p>
          </template>
        </search-box>
      </v-col>
    <v-col cols="12">
      <strong>Valores</strong>
      <v-divider/>
    </v-col>
      <v-col cols="12" sm="4">
        <v-text-field dense
            label="Valor"
            prefix="R$"
            v-model="value.valorDetalhe" type="number"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field dense
            label="Valor Estimado"
            prefix="R$"
            v-model="value.valorEstimado" type="number"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field dense
            label="Valor Referencial"
            prefix="R$"
            v-model="value.valorReferencial" type="number"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field dense
            label="Valor Última Compra"
            prefix="R$"
            v-model="value.valorUltimaCompra" type="number"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field dense
            label="Valor Total Planejado"
            prefix="R$"
            v-model="value.valorTotalPlanejado" type="number"/>
      </v-col>
    <v-col cols="12">
      <strong>Quantidades</strong>
      <v-divider/>
    </v-col>
      <v-col cols="12" sm="4">
        <v-text-field dense
            label="* Quantidade"
            :rules="[
                this.$validators.number.required,
                v => this.$validators.number.greaterThanOrEquals(v, 0)
            ]"
            v-model="value.quantidade" type="number"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field dense
            label="Qtd Consumo Anterior"
            v-model="value.qtdConsumoAnterior" type="number"/>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field dense
            label="Saldo em Estoque"
            v-model="value.saldoEstoque" type="number"/>
      </v-col>
    <v-col cols="12">
      <strong>Justificativa</strong>
      <v-divider/>
    </v-col>
      <v-col cols="12" sm="12">
        <v-textarea 
                    dense
                    rows="6"
                    label="Justificativa"
                    :rules="[
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 4000)
                    ]"
                    v-model.trim="value.justificativa"/>
      </v-col>
  </v-row>
</template>

<script>
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
//PKs
import {findAll as findAllItem} from "../../../api/item";
import {findAll as findAllUnidade} from "../../../api/unidade";
import {findAll as findAllOrgao} from "../../../api/orgao";
import {findAll as findAllRegiao} from "../../../api/regiao";
import {findAll as findAllAlmoxarifado} from "../../../api/almoxarifado";
import {findAll as findAllUnidadeMedida} from "../../../api/unidadeMedida";

export default {
  name: "PacForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      error: null,
    }
  },
  computed: {
    labelItem(){
      return "* Item" + (this.value.descTipoItem ? " tipo " + this.value.descTipoItem : "");
    },
    item() {
      if (this.value.idItem == null) return null;
      return {
        id: this.value.idItem,
        descricaoFormatada: this.value.descricaoItem,
        descTipoItem: this.value.descTipoItem,
      }
    },
    unidade() {
      if (this.value.idUnidade == null) return null;
      return {
        id: this.value.idUnidade,
        descricaoFormatada: this.value.descricaoUnidade
      }
    },
    orgao() {
      if (this.value.idOrgao == null) return null;
      return {
        id: this.value.idOrgao,
        descricaoFormatada: this.value.descricaoOrgao
      }
    },
    regiao() {
      if (this.value.idRegiao == null) return null;
      return {
        id: this.value.idRegiao,
        descricaoFormatada: this.value.descricaoRegiao
      }
    },
    almoxarifado() {
      if (this.value.idAlmoxarifado == null) return null;
      return {
        id: this.value.idAlmoxarifado,
        descricaoFormatada: this.value.descricaoAlmoxarifado
      }
    },
    unidadeMedida() {
      if (this.value.idUnidadeMedida == null) return null;
      return {
        id: this.value.idUnidadeMedida,
        descricaoFormatada: this.value.descricaoUnidadeMedida
      }
    },
  },
  watch: {
    'value.idOrgao': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboUnidade.resetValues();
          this.$refs.comboUnidade.search();
        }
      }
    },
  },
  methods: {
    loaderOrgao(page, search) {
      return findAllOrgao(page, {
        cdOrgao: search,
        descricao: search,
      })
    },
    loaderUnidade(page, search) {
      return findAllUnidade(page, {
        cdUnidade: search,
        descricao: search,
        idOrgao: this.value.idOrgao
      })
    },
    loaderItem(page, search) {
      return findAllItem(page, {
        cdItem: search,
        descricao: search,
      })
    },
    loaderRegiao(page, search) {
      return findAllRegiao(page, {
        cdRegiao: search,
        descricao: search,
      })
    },
    loaderAlmoxarifado(page, search) {
      return findAllAlmoxarifado(page, {
        cdAlmoxarifado: search,
        descricao: search,
      })
    },
    loaderUnidadeMedida(page, search) {
      return findAllUnidadeMedida(page, {
        cdUnidadeMedida: search,
        descricao: search,
      })
    },
  }
}
</script>

<style scoped>

</style>