<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditPacDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <PacDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                             :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import PacDataTable from "../../components/shared/datatables/PacDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/pac";
import CreateEditPacDialog from "../../components/shared/dialogs/CreateEditPacDialog";
import {ApiError} from "../../api/core";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions,mapGetters} from "vuex";
//PKs
import {findAll as findAllItem} from "@/api/item";
import {findAll as findAllUnidade} from "@/api/unidade";
import {findAll as findAllOrgao} from "@/api/orgao";
import {findAll as findAllRegiao} from "@/api/regiao";
import {findAll as findAllAlmoxarifado} from "@/api/almoxarifado";
import {findAll as findAllUnidadeMedida} from "@/api/unidadeMedida";

export default {
  name: "index",
  components: {CreateEditPacDialog, BaseView, PacDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Ano PAC',
          column: 'anoPac',
          type: 'number'
        },
        {
          name: 'Justificativa',
          column: 'justificativa',
          type: 'text'
        },
        {
          name: 'Item',
          column: 'idItem',
          type: 'autocomplete',
          optionsFactory: (search) => findAllItem(1,{
            cdItem: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'Unidade',
          column: 'idUnidade',
          type: 'autocomplete',
          optionsFactory: (search) => findAllUnidade(1,{
            cdUnidade: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'Região',
          column: 'idRegiao',
          type: 'autocomplete',
          optionsFactory: (search) => findAllRegiao(1,{
            cdRegiao: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'Almoxarifado',
          column: 'idAlmoxarifado',
          type: 'autocomplete',
          optionsFactory: (search) => findAllAlmoxarifado(1,{
            cdAlmoxarifado: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
        {
          name: 'UnidadeMedida',
          column: 'idUnidadeMedida',
          type: 'autocomplete',
          optionsFactory: (search) => findAllUnidadeMedida(1,{
            cdUnidadeMedida: search,
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoFormatada
                }))
              })
        },
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),    
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Item do Plano de Aquisição removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {           
          this.$toast.error(`Não foi possível remover o Item do Plano de Aquisição.`, {
            timeout: 6000
          })
        }
      }
    },
    async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Pac.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>